<template>
  <div class="w center_all citationPushDetails">
    <div class="center_2">
      <div class="header">
        <ul class="crumbs clearfix">
          <li>传播表现推送推送</li>
        </ul>
      </div>
      <div class="content-box">
        <div class="fl">
          <span class="tj_year fl">选取年份:</span>
          <el-select
            v-model="chooseYearVal"
            placeholder="请选择"
            class="fl"
            style="width: 120px; top: 3px;"
            @change="chooseYearChange"
          >
            <el-option
              v-for="item in yearList"
              :key="item.year"
              :label="item.year"
              :value="item.year"
            />
          </el-select>
        </div>
        <div class="fl">
          <span class="tj_year fl">选取刊期:</span>
          <el-select
            v-model="chooseIssueVal"
            placeholder="请选择"
            class="fl"
            style="width: 120px; top: 3px;"
            @change="chooseIssueChange"
          >
            <el-option
              v-for="item in issueList"
              :key="item.issue"
              :label="item.issue"
              :value="item.issue"
            />
          </el-select>
        </div>
        <SimpleTable
          v-loading="loading"
          ref="taskList"
          showChoose
          :tableData="tableData"
          :columnData="tableColumn"
          :initObj="tablePageObj"
          @onChangePageData="
            (pageObj) => {
              getTableList(pageObj)
            }
          "
          @handleItemSelectChange="handleItemSelectChange"
          @onRowClick="handleRowClick"
          @onClickTitle="handleOnClickTitle"
        />
        <div class="btns">
          <el-button @click="toPreview" :disabled="!(currentRow && currentRow.authorId)">邮件预览</el-button>
          <el-button @click="toWeChatPreview" :disabled="!currentRow || !currentRow.authorId">微信预览</el-button>
          <el-button :disabled="!currentRow" @click="tryPush">试推送</el-button>
          <el-button @click="pushTask">邮件推送</el-button>
          <el-button @click="pushWeChatTask">微信推送</el-button>
        </div>
        <!--  试推送弹框  -->
        <el-dialog
          title="试推送"
          :visible.sync="dialogFormVisible"
          width="600px"
          :show-close="true"
        >
          <el-form :model="tryPushData" :rules="tryPushRules">
            <el-form-item label="试推送邮箱：" prop="email" label-width="120px">
              <el-input
                v-model="tryPushData.email"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="tryPushConfirm">确 定</el-button>
            <el-button @click="dialogFormVisible = false">取 消</el-button>
          </div>
        </el-dialog>
        <citationPushDialog
          ref="citationPushDialog"
          @pushSuccess="handlePushSuccess"
        ></citationPushDialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SimpleTable from '@/components/SimpleTable'
import { ArticleAuthorList, GetYearAndIssue, TryPush, sendWechatArticleCitation } from '@/api/citationPush'
import citationPushDialog from './components/citationPushDialog'

export default {
  name: 'citationPushDetails',
  components: { SimpleTable, citationPushDialog },
  props: {},
  data() {
    return {
      tableData: [],
      tableColumn: [
        {
          title: '文章标题',
          prop: 'articleTitle',
          notSortable: true,
          width: '80px',
        },
        {
          title: '年期',
          prop: 'yearIssue',
          notSortable: true,
          width: '90px',
        },
        {
          title: '学者姓名',
          prop: 'authorId',
          notSortable: true,
          width: '100px',
          select: true,
        },
        {
          title: '浏览量新增-官网',
          prop: 'newPageReview',
          notSortable: true,
          width: '74px',
        },
        {
          title: '浏览量累计-官网',
          prop: 'sumPageReview',
          notSortable: true,
          width: '74px',
        },
        {
          title: '下载量新增-官网',
          prop: 'newOfficialDownloadCount',
          notSortable: true,
          width: '74px',
        },
        {
          title: '下载量累计-官网',
          prop: 'sumOfficialDownloadCount',
          notSortable: true,
          width: '74px',
        },
        {
          title: '下载量新增-知网',
          prop: 'newZwDownLoadCount',
          notSortable: true,
          width: '74px',
        },
        {
          title: '下载量累计-知网',
          prop: 'sumZwDownLoadCount',
          notSortable: true,
          width: '74px',
        },
        {
          title: '被引量累计-知网',
          prop: 'cnkiCitations',
          notSortable: true,
          width: '74px',
        },
        {
          title: '被引量累计-CSCD',
          prop: 'cscdCitations',
          notSortable: true,
          width: '80px',
        },
        {
          title: '被引量累计-Scopus',
          prop: 'scopusCitations',
          notSortable: true,
          width: '86px',
        },
        {
          title: '被引量累计-Crossref',
          prop: 'crossrefCitations',
          notSortable: true,
          width: '92px',
        },
      ],
      tablePageObj: {
        page: 1,
        size: 10,
      },
      chooseYearVal: '',
      chooseIssueVal: '',
      yearList: [],
      currentRow: null,
      tryPushData: {
        email: '',
      },
      dialogFormVisible: false, // 试推送 弹框
      tryPushRules: {
        email: [
          { message: '请填写试推送邮箱地址', trigger: 'blur', required: true },
        ],
      },
      loading: true,
    }
  },
  watch: {
    magId() {
      this.getTableList()
    },
  },
  computed: {
    ...mapGetters({
      magId: 'magId',
    }),
    issueList() {
      let arr = []
      if (this.yearList.length > 0) {
        this.yearList.map((item) => {
          if (item.year === this.chooseYearVal) {
            arr = item.issueList
          }
        })
      }
      return arr
    },
  },
  mounted() {
    this.getYearIssueList()
  },
  methods: {
    getTableList(pageParams = {}) {
      let { page = 1, size = 10 } = pageParams
      this.loading = true
      let params = {
        page: page - 1,
        size,
        year: this.chooseYearVal,
        issue: this.chooseIssueVal,
        taskId: this.$route.query.taskId,
      }
      ArticleAuthorList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.loading = false
          this.tableData = data.content
          this.tablePageObj = {
            ...this.tablePageObj,
            page,
            size,
            total: data.totalElements,
          }
          if (this.tableData.length > 0) {
            this.tableData.map((item) => {
              item.yearIssue =
                (item.articleYear ? item.articleYear + '年' : '') +
                (item.articleIssue ? item.articleIssue + '期' : '')
              if (item.authorList.length > 0) {
                item.authorList.map((it) => {
                  it.label = it.name
                  it.value = it.pushMagCitationAuthorId
                })
                item.authorId = item.authorList[0].pushMagCitationAuthorId
                item.selectOptions = item.authorList
              }
              item.id = item.articleId
            })
            this.$nextTick(() => {
              this.$refs.taskList.handleRowClick(this.tableData[0])
            })
          } else {
            this.$refs.taskList.initData()
            this.handleRowClick('')
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    handleRowClick(chooseId, item) {
      this.$nextTick(() => {
        this.currentRow = item
      })
    },
    getYearIssueList() {
      let params = {
        taskId: this.$route.query.taskId,
      }
      GetYearAndIssue(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.yearList = data
          if (this.yearList.length > 0) {
            this.chooseYearVal = this.yearList[0].year
            if (this.yearList[0].issueList.length > 0) {
              this.chooseIssueVal = this.yearList[0].issueList[0].issue
            }
          }
          this.getTableList()
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    toPreview() {
      let routeData = this.$router.resolve({
        name: 'citationPushPreviewHtml',
        query: {
          authorId: this.currentRow.authorId,
        },
      })
      window.open(routeData.href, '_blank')
    },
    toWeChatPreview() {
      let url = window.location.origin + '/otherPages/Microblog/html/citationWeChatPushDetails.html?authorId=' + this.currentRow.authorId + '&articleId=' + this.currentRow.articleId + '&taskId=' + this.$route.query.taskId
      window.open(url,'_blank')
    },
    handleItemSelectChange(val, item, type, prop) {
      this.$set(this.tableData[item.index], 'authorId', val)
      item.authorId = val
      this.tableData[item.index].authorId = val
      this.$forceUpdate()
    },
    chooseYearChange(val) {
      this.chooseYearVal = val
      if (this.yearList.length > 0) {
        this.yearList.map((item) => {
          if (item.year === val && item.issueList.length > 0) {
            this.chooseIssueVal = item.issueList[0].issue
          }
        })
      }
      this.getTableList()
    },
    chooseIssueChange(val) {
      this.chooseIssueVal = val
      this.getTableList()
    },
    tryPush() {
      this.dialogFormVisible = true
    },
    // 试推送 弹框 确认
    tryPushConfirm() {
      if (!this.tryPushData.email) {
        this.$message({
          type: 'warning',
          message: '请填写试推送邮箱地址!',
        })
        return
      }
      this.dialogFormVisible = false
      this.$confirm(
        `您即将进行对标题为${this.currentRow.articleTitle}试推送,是否现在推送?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'btn-custom-cancel',
          type: 'warning',
        },
      )
        .then(() => {
          let params = {
            historyId: this.$route.query.taskId,
            email: this.tryPushData.email,
            pushMagCitationAuthorId: this.currentRow.authorId,
          }
          TryPush(params).then((res) => {
            if (res && res.data && res.data.status === 0) {
              this.$message({
                type: 'success',
                message: '推送成功!',
              })
            } else {
              const { message = '' } = res.data
              this.$message.error(message)
            }
          })
        })
        .catch(() => {})
    },
    pushTask() {
      let item = {
        topic: this.$route.query.topic,
        id: this.$route.query.taskId,
      }
      this.$refs.citationPushDialog.dialogPushVisible = true
      this.$refs.citationPushDialog.currentTask = item
    },
    handlePushSuccess() {
      this.$router.push({
        name: 'citationPush',
      })
    },
    handleOnClickTitle(row) {
      window.open(row.htmlUrl, '_blank')
    },
    pushWeChatTask() {
      this.$confirm('确定进行微信推送？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'btn-custom-cancel',
        type: 'warning',
      })
        .then(() => {
          sendWechatArticleCitation({
            taskId: this.$route.query.taskId,
            magId: this.magId
          }).then((res) => {
            if (res && res.data && res.data.status === 0) {
              this.$message({
                type: 'success',
                message: '推送成功',
                showClose: true,
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
            showClose: true,
          })
        })
    }
  },
}
</script>

<style lang="scss" scoped>
.citationPushDetails {
  min-height: 400px;

  .content-box {
    .fl {
      display: flex;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 10px;
    }
  }
}
</style>
