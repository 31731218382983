<!-- citationPushDialog -->
<template>
  <div class="citationPushDialog">
    <el-dialog :visible.sync="dialogPushVisible" width="400px" :show-close="true" class="pushVisible" title="提示">
      <p class="pushDialog">您即将对{{ currentTask ? currentTask.topic : '' }}内容进行正式推送</p>
      <div class="code-info">
        <el-form ref="codeForm" :model="codeForm" :rules="codeFormRules" status-icon label-width="80px">
          <el-form-item label="手机号：" prop="receiver" size="zdy">
            <el-input v-model="codeForm.receiver" />
          </el-form-item>
          <el-form-item label="验证码：" size="zdy" prop="code" class="code">
            <el-input v-model="codeForm.code" />
            <el-button type="text" :disabled="codeDisabled" @click="getCode">{{ codeText }}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPushVisible = false">取 消</el-button>
        <el-button type="primary" @click="fanalPushBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { TaskSend, SendReceiverMessage } from '@/api/citationPush'

export default {
  name: 'citationPushDialog',
  data() {
    var validateReceiver = (rule, value, callback) => {
      var reg = /^[1][3,4,5,7,8,9][0-9]{9}$/
      if (!reg.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    return {
      pageObj: {},
      dialogPushVisible: false,
      currentTask: null,
      currentType: null, //空 默认为扩展推送（邮件推送也为扩展推送）1 扩展推送 是会做备份 4 激活学者推送
      codeForm: {
        receiver: '',
        code: ''
      },
      codeFormRules: {
        receiver: [
          {
            validator: validateReceiver,
            trigger: 'blur',
            required: true
          }
        ],
        code: [{ message: '请填写验证码', trigger: 'blur', required: true }]
      },
      codeText: '获取验证码',
      codeDisabled: true,
      refleshDisabled: false
    }
  },
  watch: {
    'codeForm.receiver'(value) {
      var reg = /^[1][3,4,5,7,8,9][0-9]{9}$/
      this.codeDisabled = !reg.test(value)
    }
  },
  props: {},
  components: {},
  computed: {},
  mounted() {},
  methods: {
    initData() {
      this.$nextTick(() => {
        this.$refs['codeForm'].resetFields()
      })
    },
    // 获取验证码
    getCode() {
      this.countdown(60)
      let params = {
        receiver: this.codeForm.receiver,
        systemCode: 'PUSH_CITATION_CODE'
      }
      SendReceiverMessage(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message({
            type: 'success',
            message: '发送成功!'
          })
        }
      })
    },
    // 倒计时
    countdown(time) {
      if (time === 0) {
        this.codeText = '获取验证码'
        this.codeDisabled = false
      } else {
        this.codeText = `${time}秒后重新获取`
        this.codeDisabled = true
        time--
        setTimeout(() => {
          this.countdown(time)
        }, 1000)
      }
    },
    // 最终推送确定
    fanalPushBtn() {
      this.$refs['codeForm'].validate((valid) => {
        if (valid) {
          let params = {
            id: this.currentTask.id,
            nameSpace: window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/das-api',
            operator: sessionStorage.getItem('realName'),
            receiver: this.codeForm.receiver,
            code: this.codeForm.code
          }
          TaskSend(params).then((res) => {
            if (res && res.data && res.data.status === 0) {
              this.$message({
                type: 'success',
                message: '推送成功!'
              })
              this.dialogPushVisible = false
              this.$emit('pushSuccess')
            } else {
              const { message = '' } = res.data
              this.$message.error(message)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.citationPushDialog {
  .code {
    .el-form-item__content {
      display: flex;
      .el-button--text {
        top: 0;
        margin-left: 10px;
        text-indent: 0;
      }
    }
  }
}
.el-message {
  z-index: 3040;
}
</style>
<style lang="scss" scoped>
.citationPushDialog {
  // 推送弹框
  .pushVisible {
    background: rgba(0, 0, 0, 0.3) !important;
    .pushDialog button {
      color: #265cca;
      cursor: pointer;
      border: none;
      background: none;
      outline: none;
    }
    .txtDisabled {
      cursor: no-drop !important;
      color: #91949d !important;
    }
  }
}
.code-info {
  margin-top: 30px;
}
.reflesh-disabled {
  color: #999 !important;
  cursor: no-drop !important;
}
</style>
