import request from './services/axios.js'

export function GetCitationList(params) {
  return request({
    params,
    url: '/vas/push/citation/getPage',
    method: 'get'
  })
}

// 引证推送新增编辑
export function NewEditCitation(params) {
  return request({
    data: params,
    url: '/vas/push/citation/save',
    method: 'post'
  })
}

// 引证推送删除
export function DeleteCitation(params) {
  return request({
    params,
    url: '/vas/push/citation/delete',
    method: 'get'
  })
}
//引证推送-发送验证码
export function SendReceiverMessage(params) {
  return request({
    params,
    url: '/vas/AlidayuVerifycode/sendReceiverMessage',
    method: 'get'
  })
}
//引证推送-引证推送任务推送
export function TaskSend(params) {
  return request({
    params,
    url: '/vas/push/citation/send',
    method: 'get'
  })
}
//引证推送-引证推送学者是否计算完毕
export function CheckInitCitationAuthorTask(params) {
  return request({
    params,
    url: '/vas/push/citation/checkInitCitationAuthorTask',
    method: 'get'
  })
}
//引证推送-推送文章和学者列表
export function ArticleAuthorList(params) {
  return request({
    params,
    url: '/vas/push/citation/articleAuthorList',
    method: 'get'
  })
}
//引证推送-获得年期接口
export function GetYearAndIssue(params) {
  return request({
    params,
    url: '/vas/push/citation/getYearAndIssue',
    method: 'get'
  })
}
//引证推送-刷新学者
export function InitPushMagCitationHistoryAuthor(params) {
  return request({
    params,
    url: '/vas/push/citation/initPushMagCitationHistoryAuthor',
    method: 'get'
  })
}
//引证推送-试推送
export function TryPush(params) {
  return request({
    params,
    url: '/vas/push/citation/sendOne',
    method: 'get'
  })
}
//引证推送-试推送
export function sendWechatArticleCitation(params) {
  return request({
    params,
    url: '/vas/wechat/sendWechatArticleCitation',
    method: 'get'
  })
}
